import React, {
  ReactNode,
  useState
} from 'react';
import { ELSIcon } from '@els/els-component-form-field-react';
import cx from 'classnames';
import { ELSModalService } from '@els/els-component-modal-react';
import {
  ELSToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import { ELSInlineLoader } from '@els/els-ui-common-react';
import {
  omit
} from 'lodash';
import FocusTrap from 'focus-trap-react';
import {
  ChatEntryAuthorDto,
  ChatEntryDto,
  ChatEntryEvaluationDto,
  ChatEntryTraceDto,
  ChatEntryTraceKey,
  ChatReferenceDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';
import {
  getNextEntryEval,
  getOsmosisVideoId,
  getRecommendations,
  isShowFeedback,
  toastApiError
} from './ai-chat.utilities';
import {
  AnalyticsAction,
  AnalyticsActionProps
} from '../../models/analytics.models';
import { OsmosisTokenDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  ADMIN_MODAL_ID,
  osmosisVideoUrl
} from './ai-chat.constants';
import {
  postChatEvaluation,
  putChatEvaluation
} from '../../apis/florence-facade/florence-facade.utilities';
import AiChatEntryCitations from './AiChatEntryCitations.component';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { AiChatProps } from './AiChat.page';
import { AiChatUserFeedbackModal } from './AiChatUserFeedbackModal.component';
import AiChatAdminTracesModal from './AiChatAdminTracesModal.component';
import { copyContentToClipboard } from '../../utilities/common.utilities';

export type AiChatEntryProps = {
  entry: ChatEntryDto;
  toastService: ELSToastService;
  modalService: ELSModalService;
  userId: string;
  trackAction: (props: AnalyticsActionProps) => void;
  onFeedbackSaveClick: (chatEval: ChatEntryEvaluationDto) => void;
  entryEvaluation: ChatEntryEvaluationDto;
  osmosisTokenDto: OsmosisTokenDto;
  isLastItem: boolean;
  isCitationLinkEnabled: boolean;
  isAdminFeatureOn: boolean;
  fetchChatEntryTracesAction: AiChatProps['fetchChatEntryTracesAction'];
  handleSaveOverride: (promptOverride: ChatEntryDto['promptOverride']) => void;
  promptOverride: ChatEntryDto['promptOverride'];
  isDirectAccessByFeatureFlag: boolean;
  isDirectAccessByIsbn: boolean;
  handleChatScroll: () => void;
  entitlements: Record<string, string>;
}

export type AiChatEntryPropsState = {
  isVideoActive: boolean;
  isReferencesActive: boolean;
}

const defaultState: AiChatEntryPropsState = {
  isVideoActive: false,
  isReferencesActive: false,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AiChatEntry = (props: AiChatEntryProps) => {
  const {
    entry,
    toastService,
    trackAction,
    osmosisTokenDto,
    onFeedbackSaveClick,
    userId,
    isLastItem,
    isCitationLinkEnabled,
    isAdminFeatureOn,
    isDirectAccessByFeatureFlag,
    isDirectAccessByIsbn,
  } = props;

  const [state, setState] = useState<AiChatEntryPropsState>(defaultState);

  const mergeState = (newState: Partial<AiChatEntryPropsState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState,
        entryEvaluation: props.entryEvaluation
      };
    });
  };

  const getEntryClasses = (chatEntry: ChatEntryDto): string => {
    const blockClass = 'c-els-ai-chat__entry';
    if (!chatEntry || !chatEntry.author) {
      return blockClass;
    }
    const modifier = `${blockClass}--${chatEntry.author.toLowerCase()}`;
    return [blockClass, modifier].join(' ');
  };

  const handleCopyClick = (event) => {

    trackAction({
      action: AnalyticsAction.AI_CHAT_COPY_CLICK,
      props: {
        entryId: entry.id,
        idx: entry.index
      }
    });

    copyContentToClipboard(props.entry.message);

    toastService.openToast({
      ...BASE_TOAST_CONFIG,
      component: (
        <div>Successfully copied</div>
      ),
      type: ELSWithToastService.types.POSITIVE
    });

    if (event.target && event.target.parentElement) {
      event.target.parentElement.focus();
    }
  };

  const toastSuccess = () => {
    props.toastService.openToast({
      ...BASE_TOAST_CONFIG,
      component: (
        <div>
          <p>Thanks for the feedback!</p>
        </div>
      ),
      type: ELSWithToastService.types.POSITIVE
    });
  };

  const handleSubmitFeedbackClick = (
    evaluation: Partial<ChatEntryEvaluationDto>,
    isFaceClick = false,
  ): Promise<ChatEntryEvaluationDto> => {

    const {
      entryEvaluation,
    } = props;

    const dto = getNextEntryEval({
      existingEntryEval: entryEvaluation,
      userId,
      entryId: entry.id,
      newEntryEval: evaluation
    });

    trackAction({
      action: AnalyticsAction.AI_CHAT_FEEDBACK_SUBMIT,
      props: {
        entryId: dto.entryId,
        rating: dto.overall,
        isFaceClick
      }
    });

    let promise;

    if (dto.id) {
      promise = putChatEvaluation(entry.chatId, dto);
    } else {
      promise = postChatEvaluation(entry.chatId, omit(dto, 'id'));
    }

    return promise.then((response) => {
      onFeedbackSaveClick(response);
      if (!isFaceClick) {
        toastSuccess();
      }
      return response;
    }).catch(() => {
      toastApiError(props.toastService);
    });
  };

  const handleFeedbackClick = (rating: number) => {

    const {
      modalService,
    } = props;

    handleSubmitFeedbackClick({ overall: rating }, true).then((entryEvaluation) => {
      const modalId = 'USER_FEEDBACK_MODAL';
      modalService.openCustomModal({
        modalId,
        dialogAriaLabel: 'Chat message feedback modal',
        modal: (
          <FocusTrap>
            <AiChatUserFeedbackModal
              overallRating={rating}
              entry={entry}
              handleSaveClick={(chatEval, isFaceClick) => {
                handleSubmitFeedbackClick(chatEval, isFaceClick);
                modalService.closeModal(modalId);
              }}
              handleCancelClick={() => {
                trackAction({
                  action: AnalyticsAction.AI_CHAT_CANCEL_FEEDBACK_CLICK,
                  props: null
                });
                modalService.closeModal(modalId);
              }}
              entryEvaluation={entryEvaluation}
              trackAction={trackAction}
            />
          </FocusTrap>
        )
      });
    });

  };

  const entryControlCssClass = 'u-els-debuttonize c-els-ai-chat__entry-control';
  const feedbackFaceCssClass = 'u-els-debuttonize c-els-ai-chat__feedback-face';

  const message = !entry.message ? <div className="u-els-padding-right-2x"><ELSInlineLoader /></div> : <pre>{entry.message}</pre>;

  const recommendations = getRecommendations(entry);

  const handleResourcesExpandToggle = () => {
    trackAction({
      action: AnalyticsAction.AI_CHAT_CITATION_EXPAND_TOGGLE,
      props: {
        statusAfterToggle: state.isReferencesActive ? 'COLLAPSED' : 'EXPANDED'
      }
    });
    mergeState({ isReferencesActive: !state.isReferencesActive });
  };

  const handleOpenAdminModal = () => {
    const {
      fetchChatEntryTracesAction,
      modalService
    } = props;
    fetchChatEntryTracesAction(entry.chatId, entry.id).then((traces: Partial<Record<ChatEntryTraceKey, ChatEntryTraceDto>>) => {
      modalService.openCustomModal({
        modalId: ADMIN_MODAL_ID,
        modal: (
          <AiChatAdminTracesModal
            entry={entry}
            traces={traces}
            toastService={toastService}
            handleClose={() => {
              modalService.closeModal(ADMIN_MODAL_ID);
            }}
          />
        )
      });
    }).catch(() => {
      toastService.openToast({
        ...BASE_TOAST_CONFIG,
        component: (
          <div>Error getting traces. Note that not all entries have traces</div>
        ),
        type: ELSWithToastService.types.NEGATIVE
      });
    });

  };

  const renderVideo = (recommendation: ChatReferenceDto): ReactNode => {
    const id = getOsmosisVideoId(recommendation);
    if (isLastItem || state.isVideoActive) {
      return (
        <div>
          <div className="u-els-margin-bottom-1o2">
            {recommendation.resourceTitle}
          </div>
          <iframe
            title="osmosis-video-iframe"
            // eslint-disable-next-line react/prop-types
            src={`${osmosisVideoUrl}/embed/${encodeURIComponent(id)}?auth=${osmosisTokenDto.authToken}`}
            className="c-els-ai-chat__osmosis-iframe"
            allowFullScreen />
        </div>
      );
    }
    return (
      <button
        type="button"
        className="u-els-anchorize u-els-debuttonize"
        onClick={() => {
          trackAction({
            action: AnalyticsAction.AI_CHAT_VIDEO_LINK_CLICK,
            props: {
              videoId: id,
              // eslint-disable-next-line react/prop-types
              entryId: entry.id,
              recommendationId: recommendation.id
            }
          });
          mergeState({ isVideoActive: true });
        }}
      >
        {recommendation.resourceTitle}
      </button>
    );
  };

  const HeadingWrapper = ({ children }: { children: ReactNode }) => {
    if (entry.author === ChatEntryAuthorDto.BOT) {
      return <h3 className="c-els-ai-chat__entry-author-heading">{children}</h3>;
    }
    return <h2 className="c-els-ai-chat__entry-author-heading">{children}</h2>;
  };

  const activeRating = props.entryEvaluation ? props.entryEvaluation.overall : null;

  return (
    <div
      key={entry.id}
      className={getEntryClasses(entry)}
    >
      <HeadingWrapper>
        <div className="c-els-ai-chat__entry-author">
          {
            entry.author === ChatEntryAuthorDto.BOT
              ? <span>Sherpath AI <span className="u-els-hide-visually"> answered: </span></span>
              : <span>You <span className="u-els-hide-visually"> asked: </span></span>
          }
        </div>
        <div
          className={cx('c-els-ai-chat__entry-main', {
            'c-els-ai-chat__entry-main--active': false
          })}
        >
          <div className="c-els-ai-chat__entry-text">
            {message}
          </div>
        </div>
      </HeadingWrapper>
      <div
        className={cx('c-els-ai-chat__entry-main', {
          'c-els-ai-chat__entry-main--active': false
        })}
      >
        {
          isShowFeedback(entry) && (
            <div className="c-els-ai-chat__entry-controls">

              <FlexLayout modifiers={[
                FlexLayoutModifier.GUTTERS,
                FlexLayoutModifier.MIDDLE,
                FlexLayoutModifier.RIGHT,
              ]}>
                <FlexItem>
                  <button
                    onClick={handleCopyClick}
                    type="button"
                    className={cx(entryControlCssClass, 'u-els-color-secondary', 'c-els-ai-chat__entry-control--copy')}
                  >
                    <ELSIcon
                      size="1x"
                      prefix="gizmo"
                      name="duplicate-document"
                      customClass="u-els-display-block"
                    />
                    <span className="u-els-hide-visually">copy</span>
                  </button>
                </FlexItem>
                <FlexItem>
                  <button
                    onClick={() => {
                      handleFeedbackClick(2);
                    }}
                    type="button"
                    aria-haspopup="dialog"
                    className={cx(
                      feedbackFaceCssClass,
                      'c-els-ai-chat__feedback-face--happy',
                      {
                        'c-els-ai-chat__feedback-face--active': activeRating === 2,
                      }
                    )}
                  >
                    <ELSIcon
                      size="1x"
                      prefix="gizmo"
                      name="thumbs-up"
                      customClass="c-els-ai-chat__feedback-face-icon"
                    />
                    <span className="u-els-hide-visually">thumbs up</span>
                  </button>
                </FlexItem>
                <FlexItem>
                  <button
                    onClick={() => {
                      handleFeedbackClick(0);
                    }}
                    type="button"
                    aria-haspopup="dialog"
                    className={cx(
                      feedbackFaceCssClass,
                      'c-els-ai-chat__feedback-face--sad',
                      {
                        'c-els-ai-chat__feedback-face--active': activeRating === 0,
                      }
                    )}
                  >
                    <ELSIcon
                      size="1x"
                      prefix="gizmo"
                      name="thumbs-down"
                      customClass="c-els-ai-chat__feedback-face-icon"
                    />
                    <span className="u-els-hide-visually">thumbs down</span>
                  </button>
                </FlexItem>
              </FlexLayout>
            </div>
          )
        }

        {
          osmosisTokenDto && recommendations && recommendations.length > 0 && (
            <div className="c-els-ai-chat__rec-list">
              <div>
                {
                  recommendations.map((recommendation) => {
                    return (
                      <div
                        key={recommendation.id}
                        className="u-els-margin-top"
                      >
                        <div className="c-els-ai-chat__rec-list-item">
                          {renderVideo(recommendation)}
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          )
        }

        <AiChatEntryCitations
          entry={entry}
          isReferencesActive={state.isReferencesActive}
          handleResourcesExpandToggle={handleResourcesExpandToggle}
          isCitationLinkEnabled={isCitationLinkEnabled}
          isDirectAccessByFeatureFlag={isDirectAccessByFeatureFlag}
          isDirectAccessByIsbn={isDirectAccessByIsbn}
          handleChatScroll={props.handleChatScroll}
          entitlements={props.entitlements}
        />

      </div>

      {
        isAdminFeatureOn && entry.author === ChatEntryAuthorDto.USER && (
          <div className="u-els-margin-top-1o2">
            <button
              type="button"
              className="u-els-anchorize c-els-ai-chat__entry-admin-link"
              onClick={handleOpenAdminModal}
            >
              <IconWithText
                iconName="eye-visible"
                iconPrefix="hmds"
              >
                View traces
              </IconWithText>
            </button>
          </div>
        )
      }
    </div>
  );
};
